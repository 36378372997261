*{
    box-sizing: border-box;
}

body{
    background-color: #d2dbdc;
    margin: 0;
    font-family: 'Segoe UI', 'Helvetica Neue', Helvetica, 'Lucida Grande',Arial;
}

.app-window{
    display: flex;
    min-height: 100vH;
    background-color: #ededed;
    min-width: 0;
}

.sidebar{
    width: 35%;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #ddd;
    background-color: whitesmoke;
    min-height: 100%;
}

.contentarea{
    background-color: #fff;
    flex: 1;
    padding: 5px 15px;
    flex-direction: column;
    flex: 1;
    min-width: 0;
}