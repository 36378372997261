.main-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.main-content-mobile{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.main-content-1{
    flex: 1;
}


.main-content-1{
    width: 200px;
    margin: 15px;
}

.content-textfield{
    margin-top: 20px;
}

.produto2-item-imagem{
    height: 184px;
    width: 244px;
    display: block;
    border-radius: 3px;
    border: 1px solid lightgrey;
    margin-top: 5px;
    
}

.avatar-uploader > .ant-upload {
    height: 180px;
    width: 240px;
    max-height: 180px;
    
  }

  .product-format-line-1{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
  }

  .antd-img-crop-modal {
    /* Custom styles */
    z-index: 9999999;
  }


  .indexZ{
      z-index: 9999999;
  }

 