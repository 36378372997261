.grupo-complementos{
    margin-top: 10px;
}

.accordion-header-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.accordion-header-content-left{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.accordion-header-content-rigth{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.accordion-body-grupo-itens{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.accordion-body-grupo-item-left{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.accordion-body-grupo-item-rigth{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.grupo-complementos-buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.group-copy-other{
   
    margin: 0px;
    padding: 0px;

}

.group-copy-other-header{
    color: black;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

