body{
    font-size: small;
 }
 
 .reciboCozinha-fontSize{
    font-size: medium;
    justify-content: center;
    text-align: center;
 }
 
 .reciboCozinha-fontSize-item{
    font-size: small;
    text-align: left;
 }
 
 .reciboCozinha-fontSize-subitem{
     font-size: small;
     text-align: left;
  }

  .reciboCozinha-fontSize-title{
   font-size: large;
   justify-content: center;
   text-align: center;
}

.reciboCozinha-fontSize-subtitle{
   font-size:medium;
   justify-content: center;
   text-align: center;
}

.reciboCozinha-fontSize-rodape{
   font-size:xx-small;
   justify-content: center;
   text-align: center;
   margin-bottom: 15px;
}

.recibo-fontSize-obs{
   font-size: xx-small;
   color: #000;
   font-style: italic;
   font-weight: normal;
   text-align: left;
 }