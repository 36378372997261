.background-modal-ativar {
    background: linear-gradient(rgba(142, 211, 243, 0.48), transparent);
    background-color: rgb(26, 107, 214);
    display: flex;
    flex-direction: column;
    padding: 30px;
    letter-spacing: .2px;
    color: antiquewhite;
    /*this your primary color*/
}

.modal-aviso-content{
    margin: -20px -24px;
    border-radius: 8px;
}