.main{
    display: flex;    
    flex-direction: column;
    align-content: space-between;
    height: 100%;
    max-height: 100vh;
}

.product-main{
    display: flex;
    flex-direction: column;
   width: 650px;
   flex: 1;
   padding-left: 15px;
   padding-right: 15px;
   overflow: auto;
}

.product-main-mobile{
    display: flex;
    flex-direction: column;
   width: 100%;
   flex: 1;
   padding: 25px 20px;
   overflow: auto;
}

.product-header{
    display: flex;
    justify-content: space-between;
    min-height: 40px;
}

.product-detalhe{
    font-size: x-large;
}

.product-line{
margin-top: 20px;
}

.product-footer{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 30px;
}