.plano-de-fundo2 {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "PT Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-repeat: no-repeat;
    background-color: #1f41a9;
    background-image: url("../../img/BG.svg");
    display: flex;
    
  }