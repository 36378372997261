.product-promotion-valores{
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: whitesmoke;
    border-radius: 5px;
    
    
}

.product-promotion-valores-line{
    display: flex;
    flex-direction: row;
    padding: 10px;   
    
}

.text-currency-promotion{
    background-color: #fff;
    font-size: 14px;
    width: 80px;
    align-items:center ;
    text-align: right;
}