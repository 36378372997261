.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
    z-index: 5000;
  }
  
  .row-dragging td {
    padding: 16px;
  }
  
  .row-dragging  {
    visibility: visible;
    
  }
  
  .drag-visible{
    visibility: visible;
  }
  
  .category-footer-order{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-align: right;
    align-content: space-between;
  }
  