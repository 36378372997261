.fp-item-imagem{
    height: 80px;
    width: 104px;
    display: block;
    border-radius: 3px;
    border: 1px solid lightgrey;
    margin-top: 5px;
    
}

.forma-de-pagamento-footer-bottons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
    z-index: 5000;
  }
  
  .row-dragging td {
    padding: 16px;
  }
  
  .row-dragging  {
    visibility: visible;
    
  }
  
  .drag-visible{
    visibility: visible;
  }

  .main-step3{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .col-img-mercadopago{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .steps-content {
    min-height: 200px;
    margin-top: 16px;
    padding-top: 80px;
    text-align: center;
    background-color: #fafafa;
    border: 1px dashed #e9e9e9;
    border-radius: 2px;
    padding-bottom: 16px;
  }
  
  .steps-action {
    margin-top: 24px;
  }
  


  .subtitle{
    font-size: .875rem !important;
    font-weight: 500;
    letter-spacing: .0071428571em !important;
    line-height: 1.375rem;
    font-family: "Roboto",sans-serif !important;
    color: rgba(129, 127, 127, 0.6);
  }

  .card_subtitle{
   
  }