.pedidos-row-footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.pedidos-col-value{
    display: flex;
    justify-content: end;
    flex-direction: row;
}