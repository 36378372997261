.dropdown {
    display: inline-block;
    position: relative;
}

.dropdown-content {
    display: none;
    position: absolute;
    width: 200%;
    overflow: auto;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.4);
}

.dropdown:focus .dropdown-content {
    display: block;
}

.dropdown-content a {
    display: block;
    color: #000000;
    padding: 5px;
    text-decoration: none;
    font-size: '0.95em';
}

.dropdown-content a:hover {
    color: #000;
    background-color: #c2c2c2;
}

.ant-popover-inner {
    padding: 3px !important;
}

:where(.css-dev-only-do-not-override-fpg3f5).ant-popover .ant-popover-inner {
    padding: 3px !important;
}