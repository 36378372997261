.backgroud-item-imagem{
    display: block;
    border-radius: 3px;
    border: 1px solid lightgrey;
    margin-top: 5px;
    
}

.telefone-brasileiro-input{
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
}






.link {
    position: relative;
    font-size: .8rem;
    color: #fff;
    text-decoration: underline;
    z-index: 100000;
    top: 0px;
    text-align: center;
}


.form {
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    border-radius: .5rem;
    padding: 0rem;
    width: 150px;
    padding: 15px;
}

form .form {
    width: 100%;
    margin-bottom: 1rem;
}

form::before {
    content: '';
    position: absolute;
    border: 1rem solid;
    border-color: #fff transparent transparent;
    bottom: -2rem;
    left: 50%;
    transform: translateX(-50%);
}

.form-div {
    color: rgb(34, 34, 34);
    font-weight: 600;
}

.form-div-2 {
    color: #5f5e5e;
    font-size: 0.85em;
}

.address-title {
    position: absolute;
    text-align: center;
    width: 100%;
    background-color: white;
    opacity: 0.8;
    height: 42px;
}

.address-title::after {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    height: 50px;
    width: 100%;
    background: linear-gradient(transparent, white, transparent);
    pointer-events: none;

}

.address-title-1 {
    color: black;
    font-weight: 600;
    margin-top: -15px;
}

.address-title-2 {
    color: rgb(0, 0, 0);
    margin-bottom: 5px;
    font-weight: 500;
   
}

.button-confirm {
    position: absolute;
    text-align: center;   
    width: 100%;
    top: 85%;
}