/*
.ant-drawer-body {
    padding: 0px !important;
}
*/
.pedido-header {
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.div-horizontal-between {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


 
 .pedido-fontSize{
    font-size: small;
 }
 
 .pedido-fontSize-item{
    font-size: small;
 }
 
 .pedido-fontSize-subitem{
     font-size: x-small;
  }
 
  .pedido-fontSize-obs{
    font-size: small;
    color: orange;
    font-style: italic;
    font-weight: normal;
  }