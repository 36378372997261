


.categoria-plus-button{
    margin-top: 15px;
    margin-bottom: 10px;
    outline: 0;
}


.category{
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 0;
    
}

.category-content{
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #FAFAFA;
    margin-bottom: 15px;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid lightgrey;
}

.category-content-header{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    min-height: 60px;
}

.category-content-title{
    font-size: large;
    font-weight: 600;
    color: #000;
    cursor: pointer;
}

.category-content-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
}

.category-content-button-text{
    font-size: small;
    margin-right: 7px;
}

.category-content-button-icon{
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 10;
}


.produto-item{
    height: 70px;
    display: flex;
    align-items:center ;
    justify-content: space-between;
}

.produto-item:hover{
   background-color: rgb(240, 239, 239);
}

.produto-item-col1{
    display: flex;
    align-items:center ;
    flex-direction: row;
    flex: 1;
    flex-wrap: wrap;
    min-width: 0;
    cursor: pointer;
}

.produto-item-text{
    display: flex;
    flex-direction: column; 
    flex: 1;

    flex-wrap: wrap;
    min-width: 0;
    width: 100%;
}

.produto-item-name{
    font-size: 14px;
    font-weight: 600;
    color: #000;
}

.produto-item-info{
    color: darkslategray;
    display: flex;
    max-height: 40px;
    width: 100%;

}

.produto-item-info p{
    font-size: 12px;
    -webkit-box-orient: vertical;
    margin-right: 15px;
      

  /*  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2; */
}


.produto-item-col2{
    display: flex;
    min-width: 0;
    align-items:center ;
}

.produto-item-imagem{
    height: 60px;
    width: 80px;
    display: block;
    border-radius: 3px;
    margin-right: 10px;
    border: 1px solid lightgrey;
    
}

.text-currency{
    background-color: #fff;
    font-size: 14px;
    width: 120px;
    align-items:center ;
}

.product-buttons-ative{

    align-items:center ;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
    outline: none;
}

.product-button-icon{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
}

.product-ative-button{
    outline: none;
}

.product-ative-button:focus{
    outline: none;
    border: 0;
}

.product-ative-button::after{
    outline: none;
    border: 0;
}

.product-ative-button::before{
    outline: none;
    border: 0;
}


.drawer-category{
    display: flex;
   width: 450px;
  
}

.cardapio-category-models{
    display: flex;
    flex-direction: row;
    padding: 5px;
    justify-content: center;
}

.cardapio-category-model{
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 5px;
    border-width: 1px;
    border-color: gray;
    cursor: pointer;
    min-width: 100px;
    margin: 5px;
}

.cardapio-product-new{
    margin: 20px 20px;
}

.contentarea-option{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.cardapio-options-buttons{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

/*
.flex-parent {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  
  
  
  .long-and-truncated-with-child-corrected {
    flex: 1;
    min-width: 0; 
    
  }
  
  .long-and-truncated-with-child-corrected h2{
    
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    
  }
*/