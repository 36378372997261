body{
   font-size: x-small;
}

.recibo-fontSize{
   font-size: x-small;
}

.recibo-fontSize-item{
   font-size: x-small;
}

.recibo-fontSize-subitem{
    font-size: xx-small;
 }

 .recibo-fontSize-obs{
   font-size: xx-small;
   color: #000;
   font-style: italic;
   font-weight: normal;
 }