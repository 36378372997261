.main{
    display: flex;    
    flex-direction: column;
    align-content: space-between;
    height: 100%;
    max-height: 100vh;
    max-height: 100%;
}

.grupo-main{
    display: flex;
    flex-direction: column;
   width: 680px;
   flex: 1;
   padding: 15px;
   overflow: auto;
}

.grupo-main-mobile{
    display: flex;
    flex-direction: column;
   width:100%;
   flex: 1;
   padding: 15px;
   overflow: auto;
}



.grupo-header{
    display: flex;
    justify-content: space-between;
    min-height: 40px;
}

.grupo-detalhe{
    font-size: x-large;
}

.grupo-line{
margin-top: 20px;
}

.grupo-footer{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 30px;
}

.grupo-item-main{
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
   align-items: initial;
}

.grupo-item-lines{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.grupo-item-line{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    width: 100%;
}

.grupo-item-icon-menu{
    margin-right: 15px;
    cursor: pointer;
    margin-top: 25px;
    color: royalblue;
}

.grupo-item-icon-delete{
    margin-left: 15px;
    cursor: pointer;
    margin-top: 25px;
    color: red;
}

.grupo-item-line-swith{
    background-color: chocolate;
    justify-content: left;
}

.text-currency-grupo{
    margin-left: 20px;
    margin-top: 15px;
    width: 130px;
}

