.main{
    display: flex;    
    flex-direction: column;
    align-content: space-between;
    height: 100vh;
    max-height: 100vh;
    max-height: 100%;
    font-size: 1em;
}

.category-main{
    display: flex;
    flex-direction: column;
   width: 650px;
   flex: 1;
   padding-left: 15px;
   padding-right: 15px;
   overflow: auto;
}

.category-main-mobile{
    display: flex;
    flex-direction: column;
   width: 90%;
   flex: 1;
   padding-left: 15px;
   padding-right: 15px;
   overflow: auto;
}

.category-header{
    display: flex;
    justify-content: space-between;
    min-height: 40px;
}

.category-detalhe{
    font-size: x-large;
}

.category-line{
margin-top: 20px;
}

.category-line-time{
    display: flex;
    flex-direction: column;
}

.category-time{
    display: flex;
    flex-direction: row;
    margin-right: 10px;
}


.categoria-footer{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 30px;
}